<template>
  <div
    id="s-dynamsoft-barcode-scanner"
    :style="`--resolution-x: ${resolution[0]}; --resolution-y: ${resolution[1]}`"
  >
    <div ref="scanner" />
  </div>
</template>

<script>
  import { BarcodeScanner, EnumBarcodeFormat } from 'dynamsoft-javascript-barcode'

  BarcodeScanner.license = process.env.VUE_APP_DYNAMSOFT_LICENSE_KEY
  BarcodeScanner.engineResourcePath = 'https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.0.0/dist/'

  export default {
    data: () => ({
      scanner: null,
      resolution: [0, 0],
    }),
    methods: {
      async init () {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

        this.scanner = await BarcodeScanner.createInstance()

        const settings = await this.scanner.getRuntimeSettings()

        await this.scanner.updateRuntimeSettings({
          ...settings,
          barcodeFormatIds:
            EnumBarcodeFormat.BF_EAN_13 |
            EnumBarcodeFormat.BF_EAN_8 |
            EnumBarcodeFormat.BF_UPC_A |
            EnumBarcodeFormat.BF_UPC_E
        })

        this.resolution = this.scanner.getResolution()

        if (isMobile) {
          this.resolution = [this.resolution[1], this.resolution[0]]
        }

        this.$refs.scanner.appendChild(this.scanner.getUIElement())

        this.scanner.onUniqueRead = (result) => {
          this.$emit('input', result)
        }

        await this.scanner.open()
      },
    },
    mounted () {
      this.$nextTick(() => this.init())
    },
    beforeDestroy () {
      this.scanner.destroyContext()
    },
  }
</script>

<style scoped>
  #s-dynamsoft-barcode-scanner::v-deep .dce-sel-camera,
  #s-dynamsoft-barcode-scanner::v-deep .dce-sel-resolution,
  #s-dynamsoft-barcode-scanner::v-deep .dce-btn-close {
    display: none !important;
  }

  #s-dynamsoft-barcode-scanner {
    position: relative;
    padding-bottom: calc(var(--resolution-y) / var(--resolution-x) * 100%);
  }

  #s-dynamsoft-barcode-scanner > * {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
</style>
