<template>
  <div id="s-html5-qrcode-scanner" />
</template>

<script>
  import { Html5Qrcode, Html5QrcodeSupportedFormats } from 'html5-qrcode'

  export default {
    data: () => ({
      barcode: null,
    }),
    async mounted () {
      const html5QrCode = new Html5Qrcode('s-html5-qrcode-scanner')

      await html5QrCode.start(
        {},
        {
          fps: 10,
          videoConstraints: {
            aspectRatio: 2,
            facingMode: 'environment',
            focusMode: 'continuous',
          },
          qrbox: { width: 250, height: 100 },
          formatsToSupport: [
            Html5QrcodeSupportedFormats.EAN_13,
            Html5QrcodeSupportedFormats.EAN_8,
            Html5QrcodeSupportedFormats.UPC_A,
            Html5QrcodeSupportedFormats.UPC_E,
            Html5QrcodeSupportedFormats.UPC_EAN_EXTENSION,
          ],
        },
        (barcode) => {
          if (barcode === this.barcode) {
            return
          }

          this.barcode = barcode
          this.$emit('input', barcode)
        },
        (e) => {
          this.$emit('error', e)
        }
      )
    }
  }
</script>

<style scoped>
  #s-html5-qrcode-scanner {
    width: 100%;
    height: 100%;
  }
</style>
