<template>
  <div class="s-barcode-scanner">
    <div>
      <Scandit @input="$emit('input', $event)" v-if="provider === PROVIDER.SCANDIT" />
      <Dynamsoft @input="$emit('input', $event)" v-if="provider === PROVIDER.DYNAMSOFT" />
      <HTML5QRcode @input="$emit('input', $event)" v-if="provider === PROVIDER.HTML5QRCODE" />
    </div>
  </div>
</template>

<script>
  import Scandit from './Scandit'
  import Dynamsoft from './Dynamsoft'
  import HTML5QRcode from './HTML5QRcode'

  const PROVIDER = {
    SCANDIT: "1",
    DYNAMSOFT: "2",
    HTML5QRCODE: "3",
  }

  export default {
    components: { Scandit, Dynamsoft, HTML5QRcode },
    props: {
      provider: {
        type: String,
        default: PROVIDER.SCANDIT,
      },
    },
    computed: {
      PROVIDER: () => PROVIDER,
    },
  }
</script>
